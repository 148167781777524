/* tslint:disable */
/* eslint-disable */
/**
 * Qonduit Rest API
 * Qonduit Rest API Docs
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Authorize200Response
 */
export interface Authorize200Response {
    /**
     * 
     * @type {string}
     * @memberof Authorize200Response
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface AuthorizeRequest
 */
export interface AuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeRequest
     */
    'identifier': string;
}
/**
 * 
 * @export
 * @interface CreateWorkflow200Response
 */
export interface CreateWorkflow200Response {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflow200Response
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CreateWorkflowProcessorRequest
 */
export interface CreateWorkflowProcessorRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowProcessorRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowProcessorRequest
     */
    'processorID': string;
    /**
     * 
     * @type {object}
     * @memberof CreateWorkflowProcessorRequest
     */
    'options': object;
    /**
     * 
     * @type {Array<CreateWorkflowProcessorRequestSubscriptionsInner>}
     * @memberof CreateWorkflowProcessorRequest
     */
    'subscriptions': Array<CreateWorkflowProcessorRequestSubscriptionsInner>;
    /**
     * 
     * @type {CreateWorkflowProcessorRequestMetadata}
     * @memberof CreateWorkflowProcessorRequest
     */
    'metadata'?: CreateWorkflowProcessorRequestMetadata;
}
/**
 * 
 * @export
 * @interface CreateWorkflowProcessorRequestMetadata
 */
export interface CreateWorkflowProcessorRequestMetadata {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowProcessorRequestMetadata
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowProcessorRequestMetadata
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowProcessorRequestMetadata
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowProcessorRequestMetadata
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateWorkflowProcessorRequestMetadata
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateWorkflowProcessorRequestMetadata
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface CreateWorkflowProcessorRequestSubscriptionsInner
 */
export interface CreateWorkflowProcessorRequestSubscriptionsInner {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowProcessorRequestSubscriptionsInner
     */
    'bucket': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowProcessorRequestSubscriptionsInner
     */
    'instanceID': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowProcessorRequestSubscriptionsInner
     */
    'workflowID': string;
}
/**
 * 
 * @export
 * @interface CreateWorkflowRequest
 */
export interface CreateWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowRequest
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof CreateWorkflowRequest
     */
    'instances': object;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowRequest
     */
    'desc': string;
    /**
     * 
     * @type {number}
     * @memberof CreateWorkflowRequest
     */
    'createAt'?: number;
}
/**
 * 
 * @export
 * @interface CreateWorkflowVariableRequest
 */
export interface CreateWorkflowVariableRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowVariableRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowVariableRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowVariableRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkflowVariableRequest
     */
    'defaultValue': string;
}
/**
 * 
 * @export
 * @interface GetVersion200Response
 */
export interface GetVersion200Response {
    /**
     * 
     * @type {string}
     * @memberof GetVersion200Response
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface GetWorkflowStatus200Response
 */
export interface GetWorkflowStatus200Response {
    /**
     * 
     * @type {boolean}
     * @memberof GetWorkflowStatus200Response
     */
    'isActive'?: boolean;
}
/**
 * Workflow Object
 * @export
 * @interface GetWorkflows200ResponseInner
 */
export interface GetWorkflows200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetWorkflows200ResponseInner
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof GetWorkflows200ResponseInner
     */
    'instances': object;
    /**
     * 
     * @type {string}
     * @memberof GetWorkflows200ResponseInner
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof GetWorkflows200ResponseInner
     */
    'desc': string;
    /**
     * 
     * @type {number}
     * @memberof GetWorkflows200ResponseInner
     */
    'createAt'?: number;
}
/**
 * 
 * @export
 * @interface StartWorkflow200Response
 */
export interface StartWorkflow200Response {
    /**
     * 
     * @type {boolean}
     * @memberof StartWorkflow200Response
     */
    'ok'?: boolean;
}
/**
 * 
 * @export
 * @interface UploadRequest
 */
export interface UploadRequest {
    /**
     * 
     * @type {object}
     * @memberof UploadRequest
     */
    'file'?: object;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _export: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _import: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthorizeRequest} authorizeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize: async (authorizeRequest: AuthorizeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorizeRequest' is not null or undefined
            assertParamExists('authorize', 'authorizeRequest', authorizeRequest)
            const localVarPath = `/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProcessorJob: async (instanceID: string, workflowID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceID' is not null or undefined
            assertParamExists('createProcessorJob', 'instanceID', instanceID)
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('createProcessorJob', 'workflowID', workflowID)
            const localVarPath = `/api/workflows/{workflowID}/processors/{instanceID}/jobs`
                .replace(`{${"instanceID"}}`, encodeURIComponent(String(instanceID)))
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateWorkflowRequest} createWorkflowRequest Workflow Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow: async (createWorkflowRequest: CreateWorkflowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWorkflowRequest' is not null or undefined
            assertParamExists('createWorkflow', 'createWorkflowRequest', createWorkflowRequest)
            const localVarPath = `/api/workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkflowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {CreateWorkflowProcessorRequest} createWorkflowProcessorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowProcessor: async (workflowID: string, createWorkflowProcessorRequest: CreateWorkflowProcessorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('createWorkflowProcessor', 'workflowID', workflowID)
            // verify required parameter 'createWorkflowProcessorRequest' is not null or undefined
            assertParamExists('createWorkflowProcessor', 'createWorkflowProcessorRequest', createWorkflowProcessorRequest)
            const localVarPath = `/api/workflows/{workflowID}/processors`
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkflowProcessorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {CreateWorkflowVariableRequest} createWorkflowVariableRequest variable Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowVariable: async (workflowID: string, createWorkflowVariableRequest: CreateWorkflowVariableRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('createWorkflowVariable', 'workflowID', workflowID)
            // verify required parameter 'createWorkflowVariableRequest' is not null or undefined
            assertParamExists('createWorkflowVariable', 'createWorkflowVariableRequest', createWorkflowVariableRequest)
            const localVarPath = `/api/workflows/{workflowID}/variables`
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkflowVariableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} jobID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcessorJob: async (instanceID: string, jobID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceID' is not null or undefined
            assertParamExists('deleteProcessorJob', 'instanceID', instanceID)
            // verify required parameter 'jobID' is not null or undefined
            assertParamExists('deleteProcessorJob', 'jobID', jobID)
            const localVarPath = `/api/processors/{instanceID}/jobs/{jobID}`
                .replace(`{${"instanceID"}}`, encodeURIComponent(String(instanceID)))
                .replace(`{${"jobID"}}`, encodeURIComponent(String(jobID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow: async (workflowID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('deleteWorkflow', 'workflowID', workflowID)
            const localVarPath = `/api/workflows/{workflowID}`
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowProcessor: async (instanceID: string, workflowID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceID' is not null or undefined
            assertParamExists('deleteWorkflowProcessor', 'instanceID', instanceID)
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('deleteWorkflowProcessor', 'workflowID', workflowID)
            const localVarPath = `/api/workflows/{workflowID}/processors/{instanceID}`
                .replace(`{${"instanceID"}}`, encodeURIComponent(String(instanceID)))
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} variableID 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowVariable: async (variableID: string, workflowID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableID' is not null or undefined
            assertParamExists('deleteWorkflowVariable', 'variableID', variableID)
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('deleteWorkflowVariable', 'workflowID', workflowID)
            const localVarPath = `/api/workflows/{workflowID}/variables/{variableID}`
                .replace(`{${"variableID"}}`, encodeURIComponent(String(variableID)))
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        execute: async (instanceID: string, workflowID: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceID' is not null or undefined
            assertParamExists('execute', 'instanceID', instanceID)
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('execute', 'workflowID', workflowID)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('execute', 'body', body)
            const localVarPath = `/api/workflow/{workflowID}/instance/{instanceID}/execute`
                .replace(`{${"instanceID"}}`, encodeURIComponent(String(instanceID)))
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} processorID 
         * @param {string} bucketName 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBucketLogs: async (processorID: string, bucketName: string, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processorID' is not null or undefined
            assertParamExists('getBucketLogs', 'processorID', processorID)
            // verify required parameter 'bucketName' is not null or undefined
            assertParamExists('getBucketLogs', 'bucketName', bucketName)
            const localVarPath = `/api/logs/processor/{processorID}/bucket/{bucketName}`
                .replace(`{${"processorID"}}`, encodeURIComponent(String(processorID)))
                .replace(`{${"bucketName"}}`, encodeURIComponent(String(bucketName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContext: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/context`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} jobID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJob: async (jobID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobID' is not null or undefined
            assertParamExists('getJob', 'jobID', jobID)
            const localVarPath = `/api/jobs/{jobID}`
                .replace(`{${"jobID"}}`, encodeURIComponent(String(jobID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tracingID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs: async (tracingID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tracingID' is not null or undefined
            assertParamExists('getLogs', 'tracingID', tracingID)
            const localVarPath = `/api/logs/{tracingID}`
                .replace(`{${"tracingID"}}`, encodeURIComponent(String(tracingID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessor: async (instanceID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceID' is not null or undefined
            assertParamExists('getProcessor', 'instanceID', instanceID)
            const localVarPath = `/api/processors/{instanceID}`
                .replace(`{${"instanceID"}}`, encodeURIComponent(String(instanceID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessorJobs: async (instanceID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceID' is not null or undefined
            assertParamExists('getProcessorJobs', 'instanceID', instanceID)
            const localVarPath = `/api/processors/{instanceID}/jobs`
                .replace(`{${"instanceID"}}`, encodeURIComponent(String(instanceID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} variableID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariable: async (variableID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableID' is not null or undefined
            assertParamExists('getVariable', 'variableID', variableID)
            const localVarPath = `/api/variables/{variableID}`
                .replace(`{${"variableID"}}`, encodeURIComponent(String(variableID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflow: async (workflowID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('getWorkflow', 'workflowID', workflowID)
            const localVarPath = `/api/workflows/{workflowID}`
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowInstanceStats: async (instanceID: string, workflowID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceID' is not null or undefined
            assertParamExists('getWorkflowInstanceStats', 'instanceID', instanceID)
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('getWorkflowInstanceStats', 'workflowID', workflowID)
            const localVarPath = `/api/workflow/{workflowID}/instance/{instanceID}/stats`
                .replace(`{${"instanceID"}}`, encodeURIComponent(String(instanceID)))
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowManualTriggers: async (workflowID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('getWorkflowManualTriggers', 'workflowID', workflowID)
            const localVarPath = `/api/workflows/{workflowID}/triggers`
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowProcessors: async (workflowID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('getWorkflowProcessors', 'workflowID', workflowID)
            const localVarPath = `/api/workflows/{workflowID}/processors`
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowStatus: async (workflowID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('getWorkflowStatus', 'workflowID', workflowID)
            const localVarPath = `/api/workflows/{workflowID}/status`
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowVariables: async (workflowID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('getWorkflowVariables', 'workflowID', workflowID)
            const localVarPath = `/api/workflows/{workflowID}/variables`
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflows: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspect: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/inspect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendData: async (instanceID: string, workflowID: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceID' is not null or undefined
            assertParamExists('sendData', 'instanceID', instanceID)
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('sendData', 'workflowID', workflowID)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sendData', 'body', body)
            const localVarPath = `/api/workflow/{workflowID}/instance/{instanceID}/inbound`
                .replace(`{${"instanceID"}}`, encodeURIComponent(String(instanceID)))
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWorkflow: async (workflowID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('startWorkflow', 'workflowID', workflowID)
            const localVarPath = `/api/workflows/{workflowID}/start`
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopWorkflow: async (workflowID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('stopWorkflow', 'workflowID', workflowID)
            const localVarPath = `/api/workflows/{workflowID}/stop`
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {CreateWorkflowRequest} createWorkflowRequest Workflow Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflow: async (workflowID: string, createWorkflowRequest: CreateWorkflowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('updateWorkflow', 'workflowID', workflowID)
            // verify required parameter 'createWorkflowRequest' is not null or undefined
            assertParamExists('updateWorkflow', 'createWorkflowRequest', createWorkflowRequest)
            const localVarPath = `/api/workflows/{workflowID}`
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkflowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {CreateWorkflowProcessorRequest} createWorkflowProcessorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowProcessor: async (instanceID: string, workflowID: string, createWorkflowProcessorRequest: CreateWorkflowProcessorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceID' is not null or undefined
            assertParamExists('updateWorkflowProcessor', 'instanceID', instanceID)
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('updateWorkflowProcessor', 'workflowID', workflowID)
            // verify required parameter 'createWorkflowProcessorRequest' is not null or undefined
            assertParamExists('updateWorkflowProcessor', 'createWorkflowProcessorRequest', createWorkflowProcessorRequest)
            const localVarPath = `/api/workflows/{workflowID}/processors/{instanceID}`
                .replace(`{${"instanceID"}}`, encodeURIComponent(String(instanceID)))
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkflowProcessorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} variableID 
         * @param {string} workflowID 
         * @param {CreateWorkflowVariableRequest} createWorkflowVariableRequest variable Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowVariable: async (variableID: string, workflowID: string, createWorkflowVariableRequest: CreateWorkflowVariableRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableID' is not null or undefined
            assertParamExists('updateWorkflowVariable', 'variableID', variableID)
            // verify required parameter 'workflowID' is not null or undefined
            assertParamExists('updateWorkflowVariable', 'workflowID', workflowID)
            // verify required parameter 'createWorkflowVariableRequest' is not null or undefined
            assertParamExists('updateWorkflowVariable', 'createWorkflowVariableRequest', createWorkflowVariableRequest)
            const localVarPath = `/api/workflows/{workflowID}/variables/{variableID}`
                .replace(`{${"variableID"}}`, encodeURIComponent(String(variableID)))
                .replace(`{${"workflowID"}}`, encodeURIComponent(String(workflowID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkflowVariableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadRequest} uploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload: async (uploadRequest: UploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadRequest' is not null or undefined
            assertParamExists('upload', 'uploadRequest', uploadRequest)
            const localVarPath = `/api/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-qonduit-admin-secret", configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _export(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._export(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _import(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._import(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthorizeRequest} authorizeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorize(authorizeRequest: AuthorizeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Authorize200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorize(authorizeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProcessorJob(instanceID: string, workflowID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProcessorJob(instanceID, workflowID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateWorkflowRequest} createWorkflowRequest Workflow Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflow(createWorkflowRequest: CreateWorkflowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWorkflow200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflow(createWorkflowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {CreateWorkflowProcessorRequest} createWorkflowProcessorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflowProcessor(workflowID: string, createWorkflowProcessorRequest: CreateWorkflowProcessorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflowProcessor(workflowID, createWorkflowProcessorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {CreateWorkflowVariableRequest} createWorkflowVariableRequest variable Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWorkflowVariable(workflowID: string, createWorkflowVariableRequest: CreateWorkflowVariableRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkflowVariable(workflowID, createWorkflowVariableRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} jobID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProcessorJob(instanceID: string, jobID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProcessorJob(instanceID, jobID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflow(workflowID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflow(workflowID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflowProcessor(instanceID: string, workflowID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflowProcessor(instanceID, workflowID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} variableID 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkflowVariable(variableID: string, workflowID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkflowVariable(variableID, workflowID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async execute(instanceID: string, workflowID: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.execute(instanceID, workflowID, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} processorID 
         * @param {string} bucketName 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBucketLogs(processorID: string, bucketName: string, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBucketLogs(processorID, bucketName, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContext(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContext(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} jobID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJob(jobID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJob(jobID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tracingID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogs(tracingID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogs(tracingID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessor(instanceID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcessor(instanceID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessorJobs(instanceID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcessorJobs(instanceID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} variableID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVariable(variableID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVariable(variableID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVersion200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflow(workflowID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflow(workflowID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowInstanceStats(instanceID: string, workflowID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowInstanceStats(instanceID, workflowID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowManualTriggers(workflowID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowManualTriggers(workflowID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowProcessors(workflowID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowProcessors(workflowID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowStatus(workflowID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWorkflowStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowStatus(workflowID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflowVariables(workflowID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflowVariables(workflowID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkflows(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetWorkflows200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkflows(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inspect(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inspect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendData(instanceID: string, workflowID: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendData(instanceID, workflowID, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startWorkflow(workflowID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartWorkflow200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startWorkflow(workflowID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopWorkflow(workflowID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartWorkflow200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopWorkflow(workflowID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {CreateWorkflowRequest} createWorkflowRequest Workflow Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkflow(workflowID: string, createWorkflowRequest: CreateWorkflowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWorkflow200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflow(workflowID, createWorkflowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {CreateWorkflowProcessorRequest} createWorkflowProcessorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkflowProcessor(instanceID: string, workflowID: string, createWorkflowProcessorRequest: CreateWorkflowProcessorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflowProcessor(instanceID, workflowID, createWorkflowProcessorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} variableID 
         * @param {string} workflowID 
         * @param {CreateWorkflowVariableRequest} createWorkflowVariableRequest variable Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkflowVariable(variableID: string, workflowID: string, createWorkflowVariableRequest: CreateWorkflowVariableRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflowVariable(variableID, workflowID, createWorkflowVariableRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadRequest} uploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upload(uploadRequest: UploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upload(uploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _export(options?: any): AxiosPromise<void> {
            return localVarFp._export(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _import(options?: any): AxiosPromise<void> {
            return localVarFp._import(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthorizeRequest} authorizeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize(authorizeRequest: AuthorizeRequest, options?: any): AxiosPromise<Authorize200Response> {
            return localVarFp.authorize(authorizeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProcessorJob(instanceID: string, workflowID: string, options?: any): AxiosPromise<void> {
            return localVarFp.createProcessorJob(instanceID, workflowID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateWorkflowRequest} createWorkflowRequest Workflow Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflow(createWorkflowRequest: CreateWorkflowRequest, options?: any): AxiosPromise<CreateWorkflow200Response> {
            return localVarFp.createWorkflow(createWorkflowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {CreateWorkflowProcessorRequest} createWorkflowProcessorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowProcessor(workflowID: string, createWorkflowProcessorRequest: CreateWorkflowProcessorRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createWorkflowProcessor(workflowID, createWorkflowProcessorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {CreateWorkflowVariableRequest} createWorkflowVariableRequest variable Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWorkflowVariable(workflowID: string, createWorkflowVariableRequest: CreateWorkflowVariableRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createWorkflowVariable(workflowID, createWorkflowVariableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} jobID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcessorJob(instanceID: string, jobID: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProcessorJob(instanceID, jobID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflow(workflowID: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkflow(workflowID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowProcessor(instanceID: string, workflowID: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkflowProcessor(instanceID, workflowID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} variableID 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkflowVariable(variableID: string, workflowID: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkflowVariable(variableID, workflowID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        execute(instanceID: string, workflowID: string, body: object, options?: any): AxiosPromise<void> {
            return localVarFp.execute(instanceID, workflowID, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} processorID 
         * @param {string} bucketName 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBucketLogs(processorID: string, bucketName: string, limit?: number, offset?: number, options?: any): AxiosPromise<void> {
            return localVarFp.getBucketLogs(processorID, bucketName, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContext(options?: any): AxiosPromise<void> {
            return localVarFp.getContext(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} jobID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJob(jobID: string, options?: any): AxiosPromise<void> {
            return localVarFp.getJob(jobID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tracingID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs(tracingID: string, options?: any): AxiosPromise<void> {
            return localVarFp.getLogs(tracingID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessor(instanceID: string, options?: any): AxiosPromise<void> {
            return localVarFp.getProcessor(instanceID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessorJobs(instanceID: string, options?: any): AxiosPromise<void> {
            return localVarFp.getProcessorJobs(instanceID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} variableID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariable(variableID: string, options?: any): AxiosPromise<void> {
            return localVarFp.getVariable(variableID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options?: any): AxiosPromise<GetVersion200Response> {
            return localVarFp.getVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflow(workflowID: string, options?: any): AxiosPromise<void> {
            return localVarFp.getWorkflow(workflowID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowInstanceStats(instanceID: string, workflowID: string, options?: any): AxiosPromise<void> {
            return localVarFp.getWorkflowInstanceStats(instanceID, workflowID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowManualTriggers(workflowID: string, options?: any): AxiosPromise<void> {
            return localVarFp.getWorkflowManualTriggers(workflowID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowProcessors(workflowID: string, options?: any): AxiosPromise<void> {
            return localVarFp.getWorkflowProcessors(workflowID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowStatus(workflowID: string, options?: any): AxiosPromise<GetWorkflowStatus200Response> {
            return localVarFp.getWorkflowStatus(workflowID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflowVariables(workflowID: string, options?: any): AxiosPromise<void> {
            return localVarFp.getWorkflowVariables(workflowID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkflows(options?: any): AxiosPromise<Array<GetWorkflows200ResponseInner>> {
            return localVarFp.getWorkflows(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspect(options?: any): AxiosPromise<void> {
            return localVarFp.inspect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendData(instanceID: string, workflowID: string, body: object, options?: any): AxiosPromise<void> {
            return localVarFp.sendData(instanceID, workflowID, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startWorkflow(workflowID: string, options?: any): AxiosPromise<StartWorkflow200Response> {
            return localVarFp.startWorkflow(workflowID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopWorkflow(workflowID: string, options?: any): AxiosPromise<StartWorkflow200Response> {
            return localVarFp.stopWorkflow(workflowID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflowID 
         * @param {CreateWorkflowRequest} createWorkflowRequest Workflow Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflow(workflowID: string, createWorkflowRequest: CreateWorkflowRequest, options?: any): AxiosPromise<CreateWorkflow200Response> {
            return localVarFp.updateWorkflow(workflowID, createWorkflowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceID 
         * @param {string} workflowID 
         * @param {CreateWorkflowProcessorRequest} createWorkflowProcessorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowProcessor(instanceID: string, workflowID: string, createWorkflowProcessorRequest: CreateWorkflowProcessorRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateWorkflowProcessor(instanceID, workflowID, createWorkflowProcessorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} variableID 
         * @param {string} workflowID 
         * @param {CreateWorkflowVariableRequest} createWorkflowVariableRequest variable Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowVariable(variableID: string, workflowID: string, createWorkflowVariableRequest: CreateWorkflowVariableRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateWorkflowVariable(variableID, workflowID, createWorkflowVariableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadRequest} uploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(uploadRequest: UploadRequest, options?: any): AxiosPromise<void> {
            return localVarFp.upload(uploadRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public _export(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration)._export(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public _import(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration)._import(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthorizeRequest} authorizeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authorize(authorizeRequest: AuthorizeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authorize(authorizeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceID 
     * @param {string} workflowID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createProcessorJob(instanceID: string, workflowID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createProcessorJob(instanceID, workflowID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateWorkflowRequest} createWorkflowRequest Workflow Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createWorkflow(createWorkflowRequest: CreateWorkflowRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createWorkflow(createWorkflowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowID 
     * @param {CreateWorkflowProcessorRequest} createWorkflowProcessorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createWorkflowProcessor(workflowID: string, createWorkflowProcessorRequest: CreateWorkflowProcessorRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createWorkflowProcessor(workflowID, createWorkflowProcessorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowID 
     * @param {CreateWorkflowVariableRequest} createWorkflowVariableRequest variable Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createWorkflowVariable(workflowID: string, createWorkflowVariableRequest: CreateWorkflowVariableRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createWorkflowVariable(workflowID, createWorkflowVariableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceID 
     * @param {string} jobID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProcessorJob(instanceID: string, jobID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteProcessorJob(instanceID, jobID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteWorkflow(workflowID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteWorkflow(workflowID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceID 
     * @param {string} workflowID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteWorkflowProcessor(instanceID: string, workflowID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteWorkflowProcessor(instanceID, workflowID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} variableID 
     * @param {string} workflowID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteWorkflowVariable(variableID: string, workflowID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteWorkflowVariable(variableID, workflowID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceID 
     * @param {string} workflowID 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public execute(instanceID: string, workflowID: string, body: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).execute(instanceID, workflowID, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} processorID 
     * @param {string} bucketName 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBucketLogs(processorID: string, bucketName: string, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getBucketLogs(processorID, bucketName, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getContext(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getContext(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} jobID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getJob(jobID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getJob(jobID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tracingID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLogs(tracingID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getLogs(tracingID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProcessor(instanceID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProcessor(instanceID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProcessorJobs(instanceID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProcessorJobs(instanceID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} variableID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVariable(variableID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVariable(variableID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVersion(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVersion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getWorkflow(workflowID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getWorkflow(workflowID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceID 
     * @param {string} workflowID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getWorkflowInstanceStats(instanceID: string, workflowID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getWorkflowInstanceStats(instanceID, workflowID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getWorkflowManualTriggers(workflowID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getWorkflowManualTriggers(workflowID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getWorkflowProcessors(workflowID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getWorkflowProcessors(workflowID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getWorkflowStatus(workflowID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getWorkflowStatus(workflowID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getWorkflowVariables(workflowID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getWorkflowVariables(workflowID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getWorkflows(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getWorkflows(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public health(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public inspect(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).inspect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceID 
     * @param {string} workflowID 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendData(instanceID: string, workflowID: string, body: object, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sendData(instanceID, workflowID, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public startWorkflow(workflowID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).startWorkflow(workflowID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stopWorkflow(workflowID: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).stopWorkflow(workflowID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflowID 
     * @param {CreateWorkflowRequest} createWorkflowRequest Workflow Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateWorkflow(workflowID: string, createWorkflowRequest: CreateWorkflowRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateWorkflow(workflowID, createWorkflowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceID 
     * @param {string} workflowID 
     * @param {CreateWorkflowProcessorRequest} createWorkflowProcessorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateWorkflowProcessor(instanceID: string, workflowID: string, createWorkflowProcessorRequest: CreateWorkflowProcessorRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateWorkflowProcessor(instanceID, workflowID, createWorkflowProcessorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} variableID 
     * @param {string} workflowID 
     * @param {CreateWorkflowVariableRequest} createWorkflowVariableRequest variable Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateWorkflowVariable(variableID: string, workflowID: string, createWorkflowVariableRequest: CreateWorkflowVariableRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateWorkflowVariable(variableID, workflowID, createWorkflowVariableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UploadRequest} uploadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public upload(uploadRequest: UploadRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).upload(uploadRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


