import React from "react";
import { createContext, useContext } from "use-context-selector";
import { Configuration, DefaultApi } from "../client";
import env from "./env";

export const context = createContext(new DefaultApi());

export const Provider = (props: { children: React.ReactNode }) => {
  const client = React.useRef(
    new DefaultApi(
      new Configuration({
        basePath: env.NEXT_PUBLIC_API_ENDPOINT,
      })
    )
  );
  return (
    <context.Provider value={client.current}>{props.children}</context.Provider>
  );
};

export const useClient = () => useContext(context);
